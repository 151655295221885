/* roboto-100normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src:
    local('Roboto Thin '),
    local('Roboto-Thin'),
    url(/static/media/roboto-latin-100.987b8457.woff2) format('woff2'), 
    url(/static/media/roboto-latin-100.e9dbbe8a.woff) format('woff'); /* Modern Browsers */
}

/* roboto-100italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src:
    local('Roboto Thin italic'),
    local('Roboto-Thinitalic'),
    url(/static/media/roboto-latin-100italic.6232f43d.woff2) format('woff2'), 
    url(/static/media/roboto-latin-100italic.d704bb3d.woff) format('woff'); /* Modern Browsers */
}

/* roboto-300normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Roboto Light '),
    local('Roboto-Light'),
    url(/static/media/roboto-latin-300.55536c8e.woff2) format('woff2'), 
    url(/static/media/roboto-latin-300.a1471d1d.woff) format('woff'); /* Modern Browsers */
}

/* roboto-300italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Roboto Light italic'),
    local('Roboto-Lightitalic'),
    url(/static/media/roboto-latin-300italic.d69924b9.woff2) format('woff2'), 
    url(/static/media/roboto-latin-300italic.210a7c78.woff) format('woff'); /* Modern Browsers */
}

/* roboto-400normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Roboto Regular '),
    local('Roboto-Regular'),
    url(/static/media/roboto-latin-400.5d4aeb4e.woff2) format('woff2'), 
    url(/static/media/roboto-latin-400.bafb105b.woff) format('woff'); /* Modern Browsers */
}

/* roboto-400italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Roboto Regular italic'),
    local('Roboto-Regularitalic'),
    url(/static/media/roboto-latin-400italic.d8bcbe72.woff2) format('woff2'), 
    url(/static/media/roboto-latin-400italic.9680d5a0.woff) format('woff'); /* Modern Browsers */
}

/* roboto-500normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Roboto Medium '),
    local('Roboto-Medium'),
    url(/static/media/roboto-latin-500.28546717.woff2) format('woff2'), 
    url(/static/media/roboto-latin-500.de8b7431.woff) format('woff'); /* Modern Browsers */
}

/* roboto-500italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src:
    local('Roboto Medium italic'),
    local('Roboto-Mediumitalic'),
    url(/static/media/roboto-latin-500italic.510dec37.woff2) format('woff2'), 
    url(/static/media/roboto-latin-500italic.ffcc050b.woff) format('woff'); /* Modern Browsers */
}

/* roboto-700normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Roboto Bold '),
    local('Roboto-Bold'),
    url(/static/media/roboto-latin-700.037d8304.woff2) format('woff2'), 
    url(/static/media/roboto-latin-700.cf6613d1.woff) format('woff'); /* Modern Browsers */
}

/* roboto-700italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Roboto Bold italic'),
    local('Roboto-Bolditalic'),
    url(/static/media/roboto-latin-700italic.010c1aee.woff2) format('woff2'), 
    url(/static/media/roboto-latin-700italic.846d1890.woff) format('woff'); /* Modern Browsers */
}

/* roboto-900normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Roboto Black '),
    local('Roboto-Black'),
    url(/static/media/roboto-latin-900.19b7a0ad.woff2) format('woff2'), 
    url(/static/media/roboto-latin-900.8c2ade50.woff) format('woff'); /* Modern Browsers */
}

/* roboto-900italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src:
    local('Roboto Black italic'),
    local('Roboto-Blackitalic'),
    url(/static/media/roboto-latin-900italic.7b770d6c.woff2) format('woff2'), 
    url(/static/media/roboto-latin-900italic.bc833e72.woff) format('woff'); /* Modern Browsers */
}


/* montserrat-100normal - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src:
    local('Montserrat Thin '),
    local('Montserrat-Thin'),
    url(/static/media/montserrat-latin-100.4124805c.woff2) format('woff2'), 
    url(/static/media/montserrat-latin-100.c8fb2f71.woff) format('woff'); /* Modern Browsers */
}

/* montserrat-100italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src:
    local('Montserrat Thin italic'),
    local('Montserrat-Thinitalic'),
    url(/static/media/montserrat-latin-100italic.e4bf47bd.woff2) format('woff2'), 
    url(/static/media/montserrat-latin-100italic.d1f3f2d0.woff) format('woff'); /* Modern Browsers */
}

/* montserrat-200normal - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src:
    local('Montserrat Extra Light '),
    local('Montserrat-Extra Light'),
    url(/static/media/montserrat-latin-200.444ae007.woff2) format('woff2'), 
    url(/static/media/montserrat-latin-200.edbce16a.woff) format('woff'); /* Modern Browsers */
}

/* montserrat-200italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-display: swap;
  font-weight: 200;
  src:
    local('Montserrat Extra Light italic'),
    local('Montserrat-Extra Lightitalic'),
    url(/static/media/montserrat-latin-200italic.f316c5d1.woff2) format('woff2'), 
    url(/static/media/montserrat-latin-200italic.d7bbb730.woff) format('woff'); /* Modern Browsers */
}

/* montserrat-300normal - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Montserrat Light '),
    local('Montserrat-Light'),
    url(/static/media/montserrat-latin-300.0a7c6df0.woff2) format('woff2'), 
    url(/static/media/montserrat-latin-300.5e86df2c.woff) format('woff'); /* Modern Browsers */
}

/* montserrat-300italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Montserrat Light italic'),
    local('Montserrat-Lightitalic'),
    url(/static/media/montserrat-latin-300italic.c076c489.woff2) format('woff2'), 
    url(/static/media/montserrat-latin-300italic.37c74a8d.woff) format('woff'); /* Modern Browsers */
}

/* montserrat-400normal - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Montserrat Regular '),
    local('Montserrat-Regular'),
    url(/static/media/montserrat-latin-400.501ce09c.woff2) format('woff2'), 
    url(/static/media/montserrat-latin-400.f29d2b85.woff) format('woff'); /* Modern Browsers */
}

/* montserrat-400italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Montserrat Regular italic'),
    local('Montserrat-Regularitalic'),
    url(/static/media/montserrat-latin-400italic.882908d9.woff2) format('woff2'), 
    url(/static/media/montserrat-latin-400italic.22e7b04e.woff) format('woff'); /* Modern Browsers */
}

/* montserrat-500normal - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Montserrat Medium '),
    local('Montserrat-Medium'),
    url(/static/media/montserrat-latin-500.f0f2716c.woff2) format('woff2'), 
    url(/static/media/montserrat-latin-500.991b453b.woff) format('woff'); /* Modern Browsers */
}

/* montserrat-500italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src:
    local('Montserrat Medium italic'),
    local('Montserrat-Mediumitalic'),
    url(/static/media/montserrat-latin-500italic.4590ebba.woff2) format('woff2'), 
    url(/static/media/montserrat-latin-500italic.f3d41e4c.woff) format('woff'); /* Modern Browsers */
}

/* montserrat-600normal - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('Montserrat SemiBold '),
    local('Montserrat-SemiBold'),
    url(/static/media/montserrat-latin-600.15c24f71.woff2) format('woff2'), 
    url(/static/media/montserrat-latin-600.f6dc6096.woff) format('woff'); /* Modern Browsers */
}

/* montserrat-600italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src:
    local('Montserrat SemiBold italic'),
    local('Montserrat-SemiBolditalic'),
    url(/static/media/montserrat-latin-600italic.6d10b805.woff2) format('woff2'), 
    url(/static/media/montserrat-latin-600italic.02c48333.woff) format('woff'); /* Modern Browsers */
}

/* montserrat-700normal - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Montserrat Bold '),
    local('Montserrat-Bold'),
    url(/static/media/montserrat-latin-700.79982cd1.woff2) format('woff2'), 
    url(/static/media/montserrat-latin-700.957e93fb.woff) format('woff'); /* Modern Browsers */
}

/* montserrat-700italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Montserrat Bold italic'),
    local('Montserrat-Bolditalic'),
    url(/static/media/montserrat-latin-700italic.283438e9.woff2) format('woff2'), 
    url(/static/media/montserrat-latin-700italic.ca627c5c.woff) format('woff'); /* Modern Browsers */
}

/* montserrat-800normal - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src:
    local('Montserrat ExtraBold '),
    local('Montserrat-ExtraBold'),
    url(/static/media/montserrat-latin-800.35386154.woff2) format('woff2'), 
    url(/static/media/montserrat-latin-800.75665590.woff) format('woff'); /* Modern Browsers */
}

/* montserrat-800italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src:
    local('Montserrat ExtraBold italic'),
    local('Montserrat-ExtraBolditalic'),
    url(/static/media/montserrat-latin-800italic.e1b52a7b.woff2) format('woff2'), 
    url(/static/media/montserrat-latin-800italic.a69f0add.woff) format('woff'); /* Modern Browsers */
}

/* montserrat-900normal - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Montserrat Black '),
    local('Montserrat-Black'),
    url(/static/media/montserrat-latin-900.260c2ea3.woff2) format('woff2'), 
    url(/static/media/montserrat-latin-900.186cae80.woff) format('woff'); /* Modern Browsers */
}

/* montserrat-900italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src:
    local('Montserrat Black italic'),
    local('Montserrat-Blackitalic'),
    url(/static/media/montserrat-latin-900italic.d785fb9f.woff2) format('woff2'), 
    url(/static/media/montserrat-latin-900italic.43b527fe.woff) format('woff'); /* Modern Browsers */
}


